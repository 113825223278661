import styled, { css } from "styled-components";

export const Container = styled.button(
  ({ theme }) => css`
    color: #ece2d6;
    transition: 0.8s;
    circle {
    transition: 0.8s;
      opacity: 0.4;
    }
    &:hover {
      color: ${theme.colors.primary.main};
      circle {
        opacity: 1;
      }
    }
  `
);

import * as Styled from "src/components/OxVideoPlayer/components/OxVideoPlayerOverlayer/OxVideoPlayerOverlayer.styled";

import { OxPlayButton } from "src/components/OxPlayButton";
import React from "react";

type TProps = {
  label?: string;
  buttonPosition?: "top" | "center" | "bottom";
  hideButton?: boolean;
  isPlay?: boolean;
};

export const OxVideoPlayerOverlayer = ({
  label,
  hideButton,
  isPlay,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  return (
    <Styled.Container {...props}>
      {!hideButton && <OxPlayButton />}
      {label && isPlay && (
        <Styled.Label text={[[label]]} animationInit={isPlay} />
      )}
    </Styled.Container>
  );
};

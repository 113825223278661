import * as Styled from "./OxPlayButton.styled";

import React from "react";

export const OxPlayButton = (
  props: SCProps<"button", unknown>
): JSX.Element => {
  return (
    <Styled.Container {...props}>
      <svg viewBox="0 0 75 74">
        <circle cx="36.4252874" cy="37" r="35" fill="#DBBEA8"></circle>
        <path
          d="M37.4252874,69.6640625 C19.4142366,69.6640625 4.76122486,55.0110508 4.76122486,37 C4.76122486,18.9889492 19.4142366,4.3359375 37.4252874,4.3359375 C55.4363381,4.3359375 70.0893499,18.9889492 70.0893499,37 C70.0893499,55.0110508 55.4363381,69.6640625 37.4252874,69.6640625 M63.5881897,10.8369531 C56.5998147,3.84857812 47.3083342,0 37.4252874,0 C27.542096,0 18.250471,3.84872266 11.2622405,10.8369531 C4.27386548,17.8253281 0.425287356,27.1169531 0.425287356,37 C0.425287356,46.8830469 4.27386548,56.1746719 11.2622405,63.1630469 C18.250471,70.1512773 27.542096,74 37.4252874,74 C47.3083342,74 56.5998147,70.1514219 63.5881897,63.1630469 C70.5765647,56.1745273 74.4252874,46.8830469 74.4252874,37 C74.4252874,27.1169531 70.5765647,17.8254727 63.5881897,10.8369531"
          fill="currentColor"
        ></path>
        <path
          d="M30.7339409,50.7049634 L30.7339409,21.2954992 L52.3867954,36.0003044 L30.7339409,50.7049634 Z M57.4658619,34.1742376 L29.774659,15.3685728 C29.1135459,14.9197007 28.2637075,14.8777104 27.5630971,15.2597199 C26.8626303,15.6415831 26.4252874,16.3852653 26.4252874,17.1946397 L26.4252874,54.805384 C26.4252874,55.6149047 26.8626303,56.3585869 27.5630971,56.7403038 C27.8816608,56.9139711 28.2309606,57 28.5795423,57 C28.9974956,57 29.4142999,56.8762237 29.774659,56.6314508 L57.4658619,37.8263713 C58.0650719,37.4191969 58.4252874,36.7338914 58.4252874,36.0001581 C58.4252874,35.2665711 58.0650719,34.5812657 57.4658619,34.1742376 L57.4658619,34.1742376 Z"
          fill="currentColor"
        ></path>
      </svg>
    </Styled.Container>
  );
};

import styled, { css } from "styled-components";
import { toPc } from "src/utils";

export const Container = styled.div<{ ratio?: number }>(
  ({ theme, ratio }) => css`
    height: 100%;
    /* position: relative; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.only("xs")} {
      position: relative;
    }
    ${ratio &&
    css`
      padding-bottom: ${toPc(ratio)};
    `}
  `
);

export const Player = styled.video<{ fitVideo?: boolean }>(
  ({ fitVideo }) => css`
    ${!fitVideo &&
    css`
      width: 100%;
    `}
    ${fitVideo &&
    css`
      left: 0;
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      min-width: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
      object-fit: cover;
    `}
  `
);

export const PlayPauseButton = styled.button<{ playing?: boolean }>(
  ({ playing }) => css`
    position: absolute;
    will-change: bottom, left, right, top, transform;
    z-index: 100;

    ${playing
      ? css`
          bottom: 0;
          right: 0;
          transform: scale(0.5);
        `
      : css`
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `}
  `
);
